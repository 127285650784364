<h2>
  Store tracking
  <button
    [disabled]="!devStoreMonitor.trackedStores().length"
    mat-icon-button
    color="accent"
    (click)="devStoreMonitor.clear()"
  >
    <cca-icon class="!text-2xl" icon="trash-can"></cca-icon>
  </button>
</h2>

<p>To enable tracking please use the `withDevTracking(myStoreName)`</p>
<br />

@for (store of devStoreMonitor.entries(); track store.name) {
  <mat-slide-toggle
    (toggleChange)="
      store.tracked
        ? devStoreMonitor.removeTrackedStore(store.name)
        : devStoreMonitor.addTrackedStore(store.name)
    "
    [checked]="store.tracked"
  >
    {{ store.name }}
  </mat-slide-toggle>
}
